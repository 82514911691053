
.bdr{
  border: 1px solid red;
}
.bdr-bl{
  border: 1px solid blue;
}
select::-ms-expand {
    display: none;
}

