
div.multiselect__tags{
    background-color:#f5f5f5;
    border: none;
    padding-top: 5px;
    padding-bottom: 3px;
}
span.multiselect__tag{
    background-color: #eee;
    border: 1px solid #ddd;
    color: #666;
}
span.multiselect__placeholder{
    padding-left: 15px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #777;
}

