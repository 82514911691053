
.hooh{
    border: 1px solid red;
}
.mediakit{
    border: none;
    margin-top: 20px;
}
.mediakit input{
    color: black;
}
.err-msg{
    background-color: red;
    color: white;
}  
